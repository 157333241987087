import React from "react";
import './home.css';

const Icon = ({title, alt, link, icon}) => {
    const src = `https://cdn.jsdelivr.net/gh/dmhendricks/signature-social-icons/icons/round-flat-filled/65px/${icon}`;

    return <div className="social-icon">
        <a 
            href={link}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img 
                className="social-img"
                src={src} 
                alt={alt} 
                title={title} 
                width="35" 
                height="35"
            />
        </a>
    </div>
}

export const Contact = props => {
    // source: https://github.com/dmhendricks/signature-social-icons
    // more unused: https://github.com/dheereshagrwal/colored-icons
    const icons = [
        {
            title: 'GitHub',
            alt: 'GitHub',
            link: 'https://github.com/daniel-olson-code',
            icon: 'github.png',
        },
        {
            title: 'LinkedIn',
            alt: 'LinkedIn',
            link: 'https://www.linkedin.com/in/daniel-w-olson/',
            icon: 'linkedin.png',
        }
    ]
    return <div className="contact-container">
        {
            icons.map(i=> <Icon {...i}/>)
        }
    </div>
}

export default Contact;