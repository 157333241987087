import React from "react";
import './home.css';
import pageInfo from './pageInfo.json';


const SingleProject = props => {
    const {title, subtitle, description, link, image} = props;
    const openLink = () => window.open(link, "_blank");
    return (
        <div className="single-project-container" onClick={()=>openLink(link)} style={{cursor: 'pointer'}}>
            <h1 className="single-project-title">{title}</h1>
            <h3 className="single-project-subtitle">{subtitle}</h3>
            <p className="single-project-description">{description}</p>
            {/* <img src={image} alt=""/> */}
        </div>
    )
}


const AllProjects = props => {
    const {projects} = pageInfo;

    return <div className="grid-container">
        <h2 className="section-title"> Top Projects </h2>
        {projects.map(p=><SingleProject {...p}/>)}
    </div>
}

export default AllProjects;