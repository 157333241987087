import HeroSection from "./Hero";
import CurrentPosition from "./CurrentPosition";
import ProjectsSection from "./Projects";
import ContactSection from "./Contact";

export const Home = props => {

    return <>
        <HeroSection/>
        <CurrentPosition/>
        <ProjectsSection/>
        <ContactSection/>
    </>
}


export default Home;