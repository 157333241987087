import React from "react";
import './home.css';
import pageInfo from './pageInfo.json';

const CurrentPosition = props => {
    const {currentPosition} = pageInfo;
    const {title, subtitle, timeline, description} = currentPosition;

    return <div>
        <h2 className="section-title">Current Position</h2>
        <div className="single-project-container" style={{cursor: 'default'}}>
            <h1 className="single-project-title">{title}</h1>
            <h3 className="single-project-subtitle">{subtitle}</h3>
            <h5 className="single-project-subtitle">{timeline}</h5>
            {/* <p className="single-project-description">{description}</p> */}
            {description.split('\n').map((line, i) => <p className="single-project-description" key={i}>{line}</p>)}
            {/* <img src={image} alt=""/> */}
        </div>
    </div>
}

export default CurrentPosition;