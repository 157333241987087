import React from 'react';
import './home.css';
import pageInfo from './pageInfo.json';

import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const Hero = props => {
    const heroInfo = pageInfo.hero;
    //e: daniel@orphos.cloud

    const handler = () => {
        navigator.clipboard.writeText('daniel@orphos.cloud');
        toast('Email copied to clipboard!');
    }
    return (
        <div className='hero-container'>
            <div className='hero-sub-container'>
                <h1 className='hero-title'>
                    {heroInfo.title}
                </h1>
                {heroInfo.subtitle.split('\n').map(line=><p className='hero-subtitle'>{line}</p>)}
                <p className='hero-description' onClick={handler}>
                    {heroInfo.description}
                </p>
            </div>
            <div className='hero-srolldown-container'>
                <div className='hero-srolldown-wrapper'>
                    <p className='hero-srolldown-text'>
                        [scroll down to see more]
                    </p>
                </div>
            </div>
            <ToastContainer 
                position='bottom-center'
                autoClose={1000}
                hideProgressBar
                closeOnClick
                transition={Flip}
            />
        </div>
    );
};

export default Hero;